<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    />
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
import moment from 'moment'
export default {
  name: 'Form',
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-92)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ]
      },
      endTime:"",
      startTime:"",
      formItemList: [
        {
          key: 'complaintTarget',
          type: 'select',
          labelName: '投诉对象',
          placeholder: '请输入投诉对象',
          option:[{
            value:"01",
            label:"平台",
          },
          {
            value:"02",
            label:"门店商户",
          },
          {
            value:"03",
            label:"回收商户",
          },
          ]
        },
        {
          key: 'staffName',
          type: 'input',
          labelName: '投诉用户',
          placeholder: '请输入投诉用户'
        },
        {
          key: 'entryTime',
          type: "pickerOptions",
          labelName: '投诉时间',
          placeholder: '请选择投诉时间',
          valueFormat:"timestamp",
          clearable: true,
        },
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
    this.handleConfirm(this.initData)
  },
  methods: {
    handleConfirm(data, cd) {
      console.log('提交了form', data)
      if(data){
        if(data.entryTime===null){
          this.startTime="",
          this.endTime=""
        }else{
          this.startTime = moment(data.entryTime[0]).format("x"),
          this.endTime = moment(data.entryTime[1]).format("x")
        }
      }else{
        this.startTime="",
        this.endTime=""
      }
      const SeachParams = {
        named:data.staffName,
        complaintTarget:data.complaintTarget,
        startTime:this.startTime,
        endTime:this.endTime,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      }
      this.$emit("changeNav",SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>
