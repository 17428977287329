<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="FeedpageNum"
      :total="Feedtotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="投诉内容" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.content" placement="top">
            <p style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.content}}</p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="投诉凭证" slot="operation" align="center">
        <template slot-scope="scope">
            <span @click="DialoghandleBtn(scope.row)" class="tableBtn">
              <span v-if="scope.row.img!==null" style="border-bottom:1px solid #0981ff">查看凭证图</span>
              <span v-if="scope.row.img===null">--</span>
            </span>
          </template>
      </el-table-column>
      <el-table-column label="投诉类型" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.complaintTarget==='01'">平台</span>
          <span v-else-if="row.complaintTarget==='02'">门店商户-{{row.companyOrMerchantName}}</span>
          <span v-else-if="row.complaintTarget==='03'">回收商户-{{row.companyOrMerchantName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="回复状态" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.state==='01'" class="StatusBtn2">未处理</span>
          <span v-else-if="row.state==='02'" class="StatusBtn1">已处理</span>
        </template>
      </el-table-column>
      
      <el-table-column width="250" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn(row, 'Reply')" v-if="row.state==='01'">回复投诉</el-button>
          <el-button size="mini" type="primary" round @click="handleBtn(row, 'Reply')" v-else-if="row.state==='02'">查看投诉</el-button>


          <el-button size="mini" type="danger" round @click="handleBtn(row, 'remark')" v-if="row.remark===null">添加备注</el-button>
          <el-button size="mini" type="danger" round @click="handleBtn(row, 'remark')" v-else-if="row.remark!==null">查看备注</el-button>

        </template>
      </el-table-column>
    </GlobalTable>
    <DialongRemark
      :QualityDialogFlag.sync="QualityDialogFlag"
      :title="title"
      :showBtn="showBtn"
      @QualityDialogSub="QualityDialogSub"
      >
      <div v-if="remarkReplyShow===true">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入备注（200字内）"
          :disabled="disabledReamk"
          maxlength="200"
          v-model="textRemark">
        </el-input>
      </div>
      <div v-if="remarkReplyShow===false">
        <div class="dialog_Reply">
          <div>投诉人：{{this.named}}</div>
          <div>回复人：{{this.personnel}}</div>
          <div>回复时间：{{this.replyTime}}</div>
        </div>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容（200字内）"
          :disabled="disabledText"
          maxlength="200"
          v-model="textReply">
        </el-input>
      </div>
    </DialongRemark>
    <!--弹框-->
    <GlobalDialog
      title='投诉凭证'
      :show="dialogVisibleOrder"
      top="2vh"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="resetPopupData"
    > 
      <div>
        <div class="block">
          <viewer :images="DialogImgList">
              <el-carousel trigger="click" height="80vh">
                <el-carousel-item v-for="item in this.DialogImgList" :key="item">
                  <div class="sec_img">
                      <img :src="item">
                    </div>
                </el-carousel-item>
              </el-carousel>
          </viewer>
        </div>
      </div>
    </GlobalDialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: ["tableData", "Feedtotal","FeedpageNum","merchantType"],
  name: "Table",
  data() {
    return {
      named:"",//弹窗投诉人
      personnel:"",//弹窗回复人
      replyTime:"",//弹窗回复时间
      showBtn:true,
      dialogVisibleOrder:false,//凭证图弹窗
      DialogImgList:[],//凭证图弹窗
      QualityDialogFlag:false,
      remarkReplyShow:true,
      disabledText:false,
      disabledReamk:false,
      title: "",
      textRemark:"",
      textReply:"",
      types:"",
      handleID:"",
      applyStatus: "",
      tableColumns: [
        { label: "投诉人", prop: "named" },
        { label: "联系电话", prop: "contact" },
        {slotName: 'operationAddress'},
        { label: "投诉时间", prop: "createTime" },
        { label: "回复时间", prop: "replyTime" },
        { label: "处理人", prop: "personnel" },
        { slotName: "operation" }
      ]
    };
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
      console.log(val)
    },
    // 表格操作
    handleBtn(row, type) {
      this.named = row.named,//弹窗投诉人
      this.personnel = row.personnel,//弹窗回复人
      this.replyTime = row.replyTime,//弹窗回复时间
      this.handleID = row.id
      console.log("表格操作", type, row);
      if (type === "remark"){
        this.remarkReplyShow = true
        this.QualityDialogFlag = true;
        if (row.remark) {
          this.textRemark = row.remark
          this.types = "02"
          this.title = "查看备注";
          this.showBtn=true
          this.disabledReamk=false
        } else {
          this.textRemark=""
          this.types = "02"
          this.showBtn=true
          this.title = "添加备注";
          this.disabledReamk=false
        }
      }else if(type ==='Reply'){
          this.remarkReplyShow = false
          this.QualityDialogFlag = true;
          if (row.state ==='01') {
            this.types = "01"
            this.textReply=""
            this.showBtn=true
            this.title = "回复投诉";
            this.disabledText=false
          } else {
            this.textReply = row.replyNote
            this.title = "查看回复";
            this.showBtn=false
            this.disabledText=true
          }
      }
    },
    // 凭证图弹窗
    DialoghandleBtn(row){
      console.log(row.img)
      this.dialogVisibleOrder = true;
      this.DialogImgList = row.img.split(',')
    },
    // //凭证图弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    //凭证图弹窗确定
    submitPopupData() {
      this.dialogVisibleOrder = false;
    },
    //弹窗确定
    QualityDialogSub() {
      const dto ={
        id: this.handleID,
        remark: this.textRemark,
        replyNote: this.textReply,
        type:this.types
      }
      _api.replyComplaint(dto).then(res => {
        if(res.code ===1){
          this.QualityDialogFlag = false;
          this.handleCurrentChange()
          this.$message.success("操作成功")
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .el-button--mini {
    width: 100px !important;
  }
  .remarkBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .StatusBtn1 {
    color: #ff687b;
  }
  .StatusBtn1 {
    color: #80c269;
  }
  .StatusBtn1 {
    color: #ffaf29;
  }
  .radioDialog {
    margin-bottom: 15px;
    color: #333333;
  }
  .review_Details{
    height: 80%;
    .DetailsRow{
      margin: 10px;
      span:first-child{
        color: black;
      }
    }
  }
  .StatusBtn1{
    color: #0981FF;
  }
  .StatusBtn2{
    color: #FF687B;
  }
  .tableBtn{
    color: #0981ff;
    cursor: pointer;
  }
  .sec_img {
      width: 20vw;
      height: 80vh;
      margin: auto;
      img{
        width: 20vw;
        height: 80vh;
        object-fit: contain;
      }
    }
    .dialog_Reply{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 20px;
      div{
        margin-bottom: 5px;
      }
    }
}
</style>
